<template>
    <b-modal
      ref="downloadInvoiceModal"
      id="download-invoice-modal"
      :title= "displayModalTitle()"
      size="sm"
      scrollable
      cancel-title="Renunță"
      ok-title="Descarcă"
      @ok="hideModal">
      <div class="text-center">
        <b-form-group label="Prețuri pentru produse" v-if="invoiceType === 'AVE' || invoiceType === 'AVZ' || invoiceType === 'AVX'">
          <b-form-radio-group
            v-model="showPrices"
            :options="showOptions"
            button-variant="outline-primary"
            buttons
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Coduri interne pentru produse">
          <b-form-radio-group
            v-model="showOwnProductCodes"
            :options="showOptions"
            button-variant="outline-primary"
            buttons
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Coduri EAN pentru produse">
          <b-form-radio-group
            v-model="showEanProductCodes"
            :options="showOptions"
            button-variant="outline-primary"
            buttons
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Coduri client pentru produse">
          <b-form-radio-group
            v-model="showClientProductCodes"
            :options="showOptions"
            button-variant="outline-primary"
            buttons
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </b-modal>
</template>

<script>
export default {
  name: 'downloadInvoiceModal',
  data() {
    return {
      invoiceIdentifier: null,
      invoiceType: null,
      context: null,

      showPrices: false,
      showOwnProductCodes: true,
      showEanProductCodes: true,
      showClientProductCodes: true,

      showOptions: [
        { text: 'Afișează', value: true },
        { text: 'Nu afișa', value: false },
      ],
    };
  },
  methods: {

    displayModalTitle() {
      if (!this.invoiceType) {
        return 'Descarcă documentul';
      }

      if (this.invoiceType === 'FAC') {
        return `Descarcă factura ${this.invoiceIdentifier.invoiceIndex}`;
      }

      return `Descarcă avizul ${this.invoiceIdentifier.invoiceIndex}`;
    },

    showModal(invoiceIdentifier, invoiceType, context) {
      this.invoiceIdentifier = invoiceIdentifier;
      this.invoiceType = invoiceType;
      this.context = context;

      this.$refs.downloadInvoiceModal.show();

      this.showPrices = invoiceType === 'FAC';
    },

    hideModal() {
      let target = `/api/client-order/invoice/${this.invoiceType}`;
      if (this.invoiceType === 'AVX') {
        target = '/api/client-order/dispatch-note';
      }
      if (this.invoiceType === 'AVE') {
        target = '/api/delivery-note';
      }

      const targetUrl = `${target}/${this.invoiceIdentifier.issuingYear}/${this.invoiceIdentifier.invoiceIndex}?displayPriceData=${this.showPrices}&displayOwnProductCodes=${this.showOwnProductCodes}&displayProductEanCodes=${this.showEanProductCodes}&displayClientProductCodes=${this.showClientProductCodes}`;

      this.$emit('downloadStarted', { url: targetUrl, invoiceType: this.invoiceType, context: this.context });
      window.location.href = targetUrl;

      this.$refs.downloadInvoiceModal.hide();
    },
  },
};
</script>
