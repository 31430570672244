<template>
  <b-modal :title="title" @show="onShow" @ok="onOk" size="xl" @hide="onHide" ref="clientDetailsModal" no-close-on-backdrop>
    <b-overlay :no-fade="!clientCode" :show="inDisplayMode && !clientCode" no-wrap>
      <template #overlay>&nbsp;</template>
    </b-overlay>
    <b-container fluid>
      <b-form-row>
        <b-col cols="4">
          <b-form-group label="Tip client">
            <b-form-select
              v-if="!initialClientCode"
              v-model="clientIsCompany"
              :options="clientTypes"
              @change="onClientIsCompanyChanged"
              autofocus/>
            <b-form-input
              v-else
              readonly
              v-model="clientIsCompany"/>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="CIF/CUI" :disabled="!clientIsCompany">
            <b-input-group>
              <b-form-input v-model="clientCode" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu CIF/CUI')"/>
              <b-input-group-append v-if="usage === 'CREATE'">
                <b-button type="button" variant="info" :disabled="xhrRequestRunning" @click="lookupCifCui"><i class="fas fa-search"/><span class="d-none d-lg-inline">&nbsp;Caută</span></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Reg. Com." :disabled="!clientIsCompany">
            <b-form-input v-model="clientRegistryOfCommerce" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu Reg. Com.')"/>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="6">
          <b-form-group label="Nume">
            <b-form-input v-model="clientName" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu numele clientului')"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Județul">
            <b-form-select
              v-if="usage === 'CREATE'"
              v-model="clientCountyCode"
              :options="counties"
              trim
              :placeholder="showPlaceholder('Alege județul')"/>
            <b-form-input
              v-else
              readonly
              v-model="countyName"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Localitatea">
            <b-form-input v-model="clientCityTown" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu localitatea')"/>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="8">
          <b-form-group label="Adresa">
            <b-form-input v-model="clientAddress" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu adresa')"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Cod postal">
            <b-form-input v-model="clientPostCode" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu cod postal')"/>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group label="Telefon">
            <b-form-input v-model="clientTel" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu telefon')"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Fax">
            <b-form-input v-model="clientFax" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu fax')"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="E-mail">
            <b-form-input v-model="clientEmail" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu e-mail')"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Web">
            <b-form-input v-model="clientWeb" :readonly="inDisplayMode" trim :placeholder="showPlaceholder('Introdu adresa web')"/>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group label="Banca" v-if="!initialClientCode">
            <b-form-input v-model="clientBankName" trim placeholder="Introdu banca"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Contul" v-if="!initialClientCode">
            <b-form-input v-model="clientBankAccount" trim placeholder="Introdu contul"/>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-container>
    <template slot="modal-footer">
      <div v-if="inDisplayMode">
        <b-button-group>
          <b-button
            :disabled="!clientCode"
            :to="{ name: 'clientDetails', params: { clientCode: this.clientCode } }"
            variant="secondary"
          >
            Vezi profil complet
          </b-button>
          <b-button
            target="_blank"
            :disabled="!clientCode"
            :to="{ name: 'clientDetails', params: { clientCode: this.clientCode } }"
            variant="secondary"
            v-b-tooltip.hover.top="'Deschide profilul într-o fereastră nouă'"
          >
            <i class="fas fa-external-link-alt"/>
          </b-button>
        </b-button-group>
        <b-button @click="hideModal" variant="primary" class="ml-1">Închide</b-button>
      </div>
      <div v-else>
        <b-button @click="hideModal" variant="secondary" class="btn btn-secondary">Abandon</b-button>
        <b-button @click="onOk" variant="primary" class="ml-1">OK</b-button>
      </div>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
div.user-name-badge {
  display: inline-block;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';

import toasts from '@/services/toasts';
import httpClient from '@/services/http-client';

export default {
  name: 'ClientDetailsModal',
  props: {
    usage: String,
    initialClientCode: String,
    callback: Function,
  },
  data() {
    return {
      client: null,
      clientError: false,

      clientIsCompany: null,

      clientCode: '',

      clientName: '',
      clientRegistryOfCommerce: '',
      clientCountyCode: null,
      clientCityTown: '',
      clientAddress: '',
      clientPostCode: '',
      clientTel: '',
      clientFax: '',
      clientEmail: '',
      clientWeb: '',
      clientBankName: '',
      clientBankAccount: '',

      clientTypes: [
        { value: null, text: 'Alege' },
        { value: false, text: 'Persoană fizică' },
        { value: true, text: 'Persoană juridică' },
      ],

      counties: [
        { value: null, text: 'Alege' },
        { value: 'AB', text: 'Alba' },
        { value: 'AG', text: 'Arges' },
        { value: 'AR', text: 'Arad' },
        { value: 'B', text: 'Bucuresti' },
        { value: 'BC', text: 'Bacau' },
        { value: 'BH', text: 'Bihor' },
        { value: 'BN', text: 'Bistrita-Nasaud' },
        { value: 'BR', text: 'Braila' },
        { value: 'BT', text: 'Botosani' },
        { value: 'BV', text: 'Brasov' },
        { value: 'BZ', text: 'Buzau' },
        { value: 'CJ', text: 'Cluj' },
        { value: 'CL', text: 'Calarasi' },
        { value: 'CS', text: 'Caras-Severin' },
        { value: 'CT', text: 'Constanta' },
        { value: 'CV', text: 'Covasna' },
        { value: 'DB', text: 'Dambovita' },
        { value: 'DJ', text: 'Dolj' },
        { value: 'GJ', text: 'Gorj' },
        { value: 'GL', text: 'Galati' },
        { value: 'GR', text: 'Giurgiu' },
        { value: 'HD', text: 'Hunedoara' },
        { value: 'HR', text: 'Harghita' },
        { value: 'IF', text: 'Ilfov' },
        { value: 'IL', text: 'Ialomita' },
        { value: 'IS', text: 'Iasi' },
        { value: 'MH', text: 'Mehedinti' },
        { value: 'MM', text: 'Maramures' },
        { value: 'MS', text: 'Mures' },
        { value: 'NT', text: 'Neamt' },
        { value: 'OT', text: 'Olt' },
        { value: 'PH', text: 'Prahova' },
        { value: 'SB', text: 'Sibiu' },
        { value: 'SJ', text: 'Salaj' },
        { value: 'SM', text: 'Satu-Mare' },
        { value: 'SV', text: 'Suceava' },
        { value: 'TL', text: 'Tulcea' },
        { value: 'TM', text: 'Timis' },
        { value: 'TR', text: 'Teleorman' },
        { value: 'VL', text: 'Valcea' },
        { value: 'VN', text: 'Vrancea' },
        { value: 'VS', text: 'Vaslui' },
        { value: 'XX', text: 'Adresă externă' },
      ],
    };
  },
  created() {
  },
  destroyed() {
  },
  computed: {
    ...mapState(['xhrRequestRunning']),
    title() {
      return this.usage === 'CREATE' ? 'Adăugare client' : 'Detalii client';
    },
    countyName() {
      const c = this.counties.find((country) => country.value === this.clientCountyCode);
      return c ? c.text : this.clientCountryCode;
    },
    inDisplayMode() {
      return this.usage !== 'CREATE';
    },
  },
  methods: {
    ...mapActions([
      'performClientDetailsQuery',
    ]),
    async onHide() {
      if (this.callback) {
        this.callback(null);
      }
    },
    showModal() {
      this.$refs.clientDetailsModal.show();
    },
    hideModal() {
      this.$refs.clientDetailsModal.hide();
    },
    showPlaceholder(placeholder) {
      return this.inDisplayMode ? '' : placeholder;
    },
    async onShow() {
      this.client = {};
      this.clientError = false;
      this.clearClientDetailsModal();

      if (this.usage === 'DETAILS') {
        try {
          const client = await this.performClientDetailsQuery(this.initialClientCode);
          this.populateClientDetailsModal(client);
          this.clientError = false;
        } catch (err) {
          this.clientError = true;
        }
      }
    },
    async lookupCifCui() {
      try {
        const response = await httpClient.post(`/api/client/query-cif?cif=${encodeURIComponent(this.clientCode)}`);

        if (response.isAxiosError || (response.response && response.response.status > 299)) {
          throw new Error(response.message);
        }

        if (response.data) {
          this.clientCode = response.data.scpTVA ? `RO${response.data.cui}` : `${response.data.cui}`;
          this.clientName = response.data.denumire;
          this.clientAddress = response.data.adresa;
          this.clientRegistryOfCommerce = response.data.nrRegCom;
          this.clientTel = response.data.telefon;
          this.clientFax = response.data.fax;
          this.clientPostCode = response.data.codPostal;
          this.clientCityTown = response.data.localitate;
          this.clientCountyCode = this.counties.find((x) => x.value === response.data.codJudet) ? response.data.codJudet : null;

          toasts.success('Am găsit date despre codul introdus');
        } else {
          toasts.error('Nu au fost găsite date despre codul introdus');
        }
      } catch (err) {
        toasts.error(`Căutarea după CIF a eșuat!\r\n\r\nMesaj eroare: ${err}`);
      }
    },

    clearClientDetailsModal() {
      this.clientName = '';
      this.clientCountyCode = null;
      this.clientCode = '';
      this.clientRegistryOfCommerce = '';
      this.clientCityTown = '';
      this.clientAddress = '';
      this.clientPostCode = '';
      this.clientTel = '';
      this.clientFax = '';
      this.clientEmail = '';
      this.clientWeb = '';
      this.clientBankName = '';
      this.clientBankAccount = '';
      this.clientIsCompany = null;
    },

    populateClientDetailsModal(client) {
      this.clientName = client.nume;
      this.clientCountyCode = client.adresaCodJudet.trim();
      this.clientCode = client.codPartener;
      this.clientRegistryOfCommerce = client.numarInregistrareRC;
      this.clientCityTown = client.adresaLocalitate;
      this.clientAddress = client.adresaStrada;
      this.clientPostCode = client.adresaCodPostal;
      this.clientTel = client.adresaTelefon;
      this.clientFax = client.adresaFax;
      this.clientEmail = client.adresaEmail;
      this.clientWeb = client.adresaWebsite;
      this.clientIsCompany = (client.tipPartenerPentruD394 === 'CNP' || client.tipPartenerPentruD394 === 'CPI')
        ? 'Persoană fizică'
        : 'Persoană juridică';
    },

    onClientIsCompanyChanged() {
      this.clientCode = null;
      this.clientRegistryOfCommerce = null;
    },

    onOk(bvModalEvt) {
      if (!this.inDisplayMode) {
        // Prevent modal from closing
        bvModalEvt.preventDefault();

        if (this.clientIsCompany === null
        || !this.clientName
        || !this.clientCountyCode
        || !this.clientCityTown
        || !this.clientAddress) {
          toasts.error('Datele clientului sunt incomplete');
          return false;
        }

        if (this.clientIsCompany && !this.clientCode) {
          toasts.error('Codul CIF nu a fost completat');
          return false;
        }

        // Do the async work next
        this.createClient();
      } else {
        this.$router.replace({
          name: 'clientDetails',
          params: {
            clientCode: this.clientCode,
          },
        });
      }
      return null;
    },

    async createClient() {
      try {
        const response = await httpClient.post('/api/client', {
          groupId: null,
          agentId: null,
          name: this.clientName,
          county: this.clientCountyCode,
          city: this.clientCityTown,
          address: this.clientAddress,
          postCode: this.clientPostCode,
          fax: this.clientFax,
          phone: this.clientTel,
          email: this.clientEmail,
          web: this.clientWeb,
          registrationNo: this.clientIsCompany ? this.clientRegistryOfCommerce : null,
          fiscalCode: this.clientIsCompany ? this.clientCode : null,
          bank: this.clientBankName,
          bankAccount: this.clientBankAccount,
          isCompany: this.clientIsCompany,
        });

        if (response.isAxiosError || (response.response && response.response.status > 299)) {
          throw new Error(response.message);
        }
        if (response.data) {
          toasts.success(`Clientul ${this.clientName} a fost adăugat cu succes`);
          this.$refs.clientDetailsModal.hide();
          this.callback(response.data);
        }
      } catch (err) {
        toasts.error(`Adăugarea de client a eșuat!\r\n\r\nMesaj eroare: ${err}`);
      }
    },
  },
};
</script>
